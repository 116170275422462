/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, {Component} from "react";
import { 
    Card, 
    CardContent, 
    Typography, 
    Divider, 
    CardActions, 
    Button, 
    Grid 
} from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';

/**
 * This component is rendered when no results are found for the given query.
 * Rendered by Results.js
 */
class NoResultFound extends Component{
    
    render(){
        return(
            <Grid container style={{marginTop: 100}} justify="center">
                <Card>
                    <CardContent>
                        <Typography variant="h5">
                            We couldn't find any matches!
                        </Typography>
                        <Typography
                            variant="body2" 
                            color="textSecondary" 
                            component="p"
                        >
                            Please check your spelling or try searching something else
                        </Typography>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Button size="small" color="primary" component={RouterLink} to="/">
                            Go To Homepage
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        );
    }
}

export default NoResultFound;