/**
 * @author - Vaibhav Varshney
 * @author - Montek Singh
 * @author - Abhilash Pandurangan
 * @version - 1.0
 */
import React from "react";
import { render } from "react-dom";
import App from "./Components/app";

render(<App />, document.getElementById("root"));
