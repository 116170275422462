/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, {Component} from "react";
import {
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Button, 
    TableContainer, 
    Table, 
    TableHead, 
    TableBody, 
    TableRow, 
    TableCell, 
    Paper, 
    Typography
} from "@material-ui/core";

/**
 * This component displays the Query Details dialog box
 * Rendered by NLPResultsSubHeader.js
 */
class QueryDetails extends Component{
    /**
     * @param  {{Object} nlpResult, {boolean} isQueryDetailsOpen, {function} closeQueryDetails} props - Props supplied by the parent
     */
    render(){

        const nlpResult = this.props.nlpResult;

        return(
            <div>
                <Dialog
                open={this.props.isQueryDetailsOpen}
                onClose={this.props.closeQueryDetails}
                scroll="paper"
                >
                    <DialogTitle>
                        Query Details
                    </DialogTitle>

                    <DialogContent dividers={true}>
                        <TableContainer component={Paper} style={{marginTop: 10, minWidth: 350}}>
                            <Table>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography style={{fontWeight:"bold"}}>Attribute</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{fontWeight:"bold"}}>Value</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        Object.keys(nlpResult).map((key, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textTransform: "capitalize",}}> 
                                                        { key } 
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography variant="body2" style={{textTransform: "capitalize",}}> 
                                                        { nlpResult[key].description.join(", ") } 
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                                
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    
                    <DialogActions>
                        <Button onClick={this.props.closeQueryDetails} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default QueryDetails;