/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, { Component }  from "react";
import {
  AppBar, 
  InputBase, 
  IconButton, 
  Paper, 
  Toolbar, 
  Grid, 
  CardActionArea, 
  withStyles 
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from '@material-ui/icons/Menu';
import { Link as RouterLink } from 'react-router-dom';
import { withRouter } from 'react-router';

/**
 * Hides the burger icon on "sm" or large screens
 * Hides the frenzy logo on "xs" screens
 * @param {Object} theme 
 */
const useStyles = (theme) => ({
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
});

/**
 * This component displays the header on the results page
 * Rendered by Results.js
 */
class QueryHeader extends Component {

  /**
   * Defines the state
   * @param {
   *  {String} rawQuery - The query entered by the user on the home page, 
   *  {Function} handleSearch - Updates the "rawQuery" sate of Results.js, 
   *  {Function} openFiltersPane - Opens the filters' drawer on "xs" screens
   * } props - Props supplied by the parent
   */
  constructor(props) {
    super(props);
    this.state = {
      value : this.props.rawQuery,
    }
    this.changeText = this.changeText.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  /**
   * Updates the state as the user types the query
   * @param {*} event - Event object of the InputBase(Search Bar)
   */
  changeText(event) {
    this.setState({value: event.target.value});
  }

  /**
   * Push the path of the results page and the query onto the location props
   * Calls "handleSearch" function of "Results.js" via props
   * @param {*} event - Event object of the InputBase(Search Bar)
   */
  handleClick(event)
  {
    let userQuery = this.state.value;

    const { history } = this.props;
    history.push({
        pathname : '/results',
        search : '?query='+userQuery+'?categoryPageSearch='+this.props.categoryPageCheckBox,
    });

    this.props.handleSearch(userQuery);
  }

  /**
   * Push the path of the results page and the query onto the location props on press of enter
   * Calls "handleSearch" function of "Results.js" via props
   * @param {*} event - Event object of the InputBase(Search Bar)
   */
  keyPress(event){
    if(event.key === "Enter"){
      let userQuery = this.state.value;

      const { history } = this.props;
      history.push({
          pathname : '/results',
          search : '?query='+userQuery+'?categoryPageSearch='+this.props.categoryPageCheckBox,
      });

      this.props.handleSearch(userQuery);
    }
 }
  
 /**
  * Renders the header of the results page
  */
  render(){
    const classes = this.props.classes;
    return (
      <AppBar>
        <Toolbar style={{backgroundColor: "black"}}>
            <Grid container direction="row" justify="space-between" alignItems="center" >
              
              <Grid item >
                <IconButton
                  color="inherit"
                  edge="start"
                  className={classes.menuButton}
                  onClick={this.props.openFiltersPane}
                >
                  <MenuIcon />
                </IconButton>
                <CardActionArea component={RouterLink} to="/">         
                  <img className={classes.logo} style={{height: 40}} src = "frenzylogo8.png" alt= "logo" />
                </CardActionArea>
              </Grid> 

              <Grid item md={5} xs={7}>
                <Paper variant="outlined" style={{height:40}}> 
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    
                    <Grid item md={11} xs={10}>
                      <InputBase
                        value={this.state.value}
                        onChange= {this.changeText}
                        style={{width: "100%", paddingLeft: 10, paddingTop: 8}}
                        margin="dense"
                        onKeyDown={this.keyPress}
                      />
                    </Grid>
                    
                    <Grid item md={1} xs={2}> 
                      <Grid container justify="flex-end">
                        <IconButton size="small" onClick = {this.handleClick} style={{align: "right", paddingRight:7, paddingTop:9}}>
                            <SearchIcon />
                        </IconButton>
                      </Grid>
                    </Grid>

                  </Grid>
                </Paper>
              </Grid>

            </Grid>
          </Toolbar> 
      </AppBar>
    );
  }
}

export default withRouter((withStyles(useStyles)(QueryHeader)));
