/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import ProductCard from "./ProductCard";
import SeletedFiltersChip from "./SelectedFiltersChips"

/**
 * This component displays the product cards and the pagination
 * Rendered by Results.js
 */
class ProductsPane extends Component{

    /**
     * Constructor - State variable holds the open/close information of the product description dialog box
     * @param {
     *  {Array} solrResults - Products array received from the API, 
     *  {Integer} pageCount - Count the total pages, 
     *  {Integer} resultsCount - Count of the products found for the user query,
     *  {Integer} currentPage - Index of the current page, 
     *  {Integer} resultsPerPage - Count of products to be displayed per page,  
     *  {Object} filters - Object  of the applied filters, 
     *  {Object} defaultPriceRange - Object of the min and max price(not the filter values), 
     *  {Function(index)} loadPage - Function to load the page with index on pagination click, 
     *  {Function(value)} deleteFilterChip - Function to remove the filters when the chips are deleted
     * } props - Props supplied by the parent
     */
    constructor(props){
        super(props);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);
    }

    /**
     * Opens the product description
     * @param {Integer} i - Index of the product card 
     */
    /*
    openProductDescription(i) {
        const isProductDescriptionOpen = this.state.isProductDescriptionOpen.slice();
        isProductDescriptionOpen[i] = true;
        this.setState({
            isProductDescriptionOpen: isProductDescriptionOpen,
        });
    };*/
    
    /**
     * Closes the product description
     * @param {Integer} i - Index of the product card
     */
    /*
    closeProductDescription(i) {
        const isProductDescriptionOpen = this.state.isProductDescriptionOpen.slice();
        isProductDescriptionOpen[i] = false;
        this.setState({
            isProductDescriptionOpen: isProductDescriptionOpen,
        });
    };*/
    
    /**
     * Renders a card for productData
     * @param {Object} productData - Object of the product details
     * @param {Integer} index - Index of the card
     */
    renderProductCard(productData, index){
        const styles = {
            Card: {
                width: 209,
                height: 370,
            },

            Image:{
                width: 200,
                height: 200,
            },

            Typography:{
                textTransform: "capitalize",
            },

            Table:{
                marginTop: 10,
                width: "100%",
            }
        };

        return(
            <Grid item key={index}>
                <ProductCard 
                    key={index} 
                    productData={productData} 
                    styles={styles}
                    categoryPageSearch={this.props.categoryPageSearch}
                />
                {/** 
                <ProductDescription 
                    key={index+50}
                    productData={productData}
                    closeProductDescription={() => this.closeProductDescription(index)}
                    isOpen={this.state.isProductDescriptionOpen[index]}
                    styles={styles}
                />*/}
            </Grid>
        );
    }

    /**
     * Loads the page with index "value" on click of a button on pagination
     * @param {*} event - Event object
     * @param {Integer} value - Index of the page to be loaded
     */
    handlePaginationChange(event, value){
        if(this.props.currentPage !== value){
            this.props.loadPage(value);
        }  
    };

    /**
     * Renders the products pane
     */
    render(){

        let solrResults = this.props.solrResults;
        let pageCount = this.props.pageCount;

        // Creating a card for each product
        let productCards = solrResults.map((productData, index) => {
            return this.renderProductCard(productData, index);
        });

        return (
            <Grid container direction="column" spacing={2}>

                <Grid item>
                    <SeletedFiltersChip
                        filters={this.props.filters}
                        currentPage={this.props.currentPage}
                        resultsPerPage={this.props.resultsPerPage}
                        pageCount={this.props.pageCount}
                        resultsCount={this.props.resultsCount}
                        defaultPriceRange={this.props.defaultPriceRange}
                        deleteFilterChip={(value) => this.props.deleteFilterChip(value)}
                        sortOrder={this.props.sortOrder}
                        handleSortOrderChange={this.props.handleSortOrderChange}
                    />
                </Grid>

                <Grid item>
                    <Grid container justify="space-evenly" spacing={3}>
                        {productCards}
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container justify="space-evenly">
                        <Pagination 
                            count={pageCount} 
                            color="primary" 
                            size="large" 
                            page={this.props.currentPage} 
                            onChange = {this.handlePaginationChange}
                        />
                    </Grid>
                </Grid>

            </Grid>
        );
    }
}

export default ProductsPane;
