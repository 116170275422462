/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, {Component} from "react";
import {
    Grid, 
    Chip, 
    Typography
} from "@material-ui/core";

import SortResults from './SortResults'

/**
 * This component displays the chips of the selected filters
 * Rendered by ProductsPane.js
 */
class SelectedFiltersChips extends Component{
    
    /**
     * Creates the chips to display the seleted filters in products pane
     * @param {
     *  {filter_name:[val1, val2,...]} filters - Object  of the applied filters,
     *  {Integer} pageCount - Count the total pages, 
     *  {Integer} resultsCount - Count of the products found for the user query,
     *  {Integer} currentPage - Index of the current page, 
     *  {Integer} resultsPerPage - Count of products to be displayed per page, 
     *  {Object} defaultPriceRange - Object of the min and max price(not the filter values),
     *  {Function(value)} deleteFilterChip - Function to remove the filters when the chips are deleted
     * } props - Props supplied by the parent
     */
    render(){
        
        const filters = this.props.filters;
        /**
         * {[filter_name, value]} selectedFiltersArray - Array to hold the data for the selected filters
         */
        const selectedFiltersArray = [];

        // Creates an array "selectedFiltersArray" from "filters" to create chips
        for(let key in filters){
            if(key === "price"){
                // If min/max value is default min/max then display the price range accordingly 
                if(filters[key]["min"] !== this.props.defaultPriceRange["min"] || filters[key]["max"] !== this.props.defaultPriceRange["max"]){
                    const min = filters[key]["min"]===this.props.defaultPriceRange["min"]? "Min":"$" + filters[key]["min"]
                    const max = filters[key]["max"]===this.props.defaultPriceRange["max"]? "Max":"$" + filters[key]["max"]
                    const priceRange = min + " - " + max;
                    selectedFiltersArray.push([key, priceRange]);
                }
                continue;
            }    
            for(let index in filters[key]){
                selectedFiltersArray.push([key, filters[key][index]]);
            }
        }

        // Chip for every selected filter
        let seletedFiltersChip = selectedFiltersArray.map((value, index) => {
            return(
                <Grid item key={index}>
                    <Chip 
                        variant="outlined"
                        label={value[1]}
                        key={index}
                        onDelete={() => this.props.deleteFilterChip(value)}
                        style={{textTransform: "capitalize"}}
                    />
                </Grid>
            );
        });

        /**
         * These numbers are displayed at the top of the products pane
         * {Integer} startIndex - Index of the first product on the current page
         * {Integer} endIndex - Index of the last product on the current page
         * {Integer} resultsCount - Number of total products found for the input query
         */
        const startIndex = (this.props.currentPage-1) * this.props.resultsPerPage + 1;
        const endIndex = this.props.currentPage===this.props.pageCount ? this.props.resultsCount : (this.props.currentPage-1) * this.props.resultsPerPage + 24;
        const resultsCount = this.props.resultsCount;

        return(
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Typography>
                        Showing {startIndex} - {endIndex} of {resultsCount} results
                    </Typography>
                </Grid>
                { seletedFiltersChip }
                
                <Grid item>
                    <SortResults
                        sortOrder={this.props.sortOrder}
                        handleSortOrderChange={this.props.handleSortOrderChange}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default SelectedFiltersChips;