/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, { Component }  from "react";
import {
  AppBar, 
  InputBase, 
  IconButton, 
  Paper, 
  Toolbar, 
  Grid, 
  CardActionArea 
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Link as RouterLink } from 'react-router-dom';
import { withRouter } from 'react-router';

/**
 * This component displays the header on the product description page
 * Rendered by ProductDescription.js
 */
class ProductDescQueryHeader extends Component {

  /**
   * Defines the state
   * @param {*} props - Props supplied by the parent
   */
  constructor(props) {
    super(props);

    this.state = {
      value : "",
    }

    this.changeText = this.changeText.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  /**
   * Updates the state as the user types the query
   * @param {*} event - Event object of the InputBase(Search Bar)
   */
  changeText(event) {
    this.setState({value: event.target.value});
  }

  /**
   * Push the path of the results page and the query onto the location props
   * @param {*} event - Event object of the InputBase(Search Bar)
   */
  handleClick(event)
  {
    const { history } = this.props;
      history.push({
          pathname : '/results',
          search : '?query='+this.state.value+'?categoryPageSearch='+this.props.categoryPageSearch
      });
  }

  /**
   * Push the path of the results page and the query onto the location props on press of enter
   * @param {*} event - Event object of the InputBase(Search Bar)
   */
  keyPress(event){
    if(event.key === "Enter"){
        const { history } = this.props;
        history.push({
            pathname : '/results',
            search : '?query='+this.state.value+'?categoryPageSearch='+this.props.categoryPageSearch
        });
    }
 }
  
 /**
  * Renders the header of the product description page
  */
  render(){
    return (
      <AppBar>
        <Toolbar style={{backgroundColor: "black"}}>
            <Grid container direction="row" justify="space-between" alignItems="center" >
              
              <Grid item >
                <CardActionArea component={RouterLink} to="/">         
                  <img style={{height: 40}} src = "frenzylogo8.png" alt= "logo" />
                </CardActionArea>
              </Grid> 

              <Grid item md={5} xs={7}>
                <Paper variant="outlined" style={{height:40}}> 
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    
                    <Grid item md={11} xs={10}>
                      <InputBase
                        value={this.state.value}
                        placeholder="Search for Clothing, Bags and more.."
                        onChange= {this.changeText}
                        style={{width: "100%", paddingLeft: 10, paddingTop: 8}}
                        margin="dense"
                        onKeyDown={this.keyPress}
                      />
                    </Grid>

                    <Grid item md={1} xs={2}> 
                      <Grid container justify="flex-end">
                        <IconButton size="small" onClick = {this.handleClick} style={{align: "right", paddingRight:7, paddingTop:9}}>
                            <SearchIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    
                  </Grid>
                </Paper>
              </Grid>

            </Grid>
          </Toolbar> 
      </AppBar>
    );
  }
}

export default withRouter(ProductDescQueryHeader);
