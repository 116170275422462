/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, {Component} from "react";
import {
    Card, 
    CardActionArea, 
    CardActions, 
    CardContent, 
    Button, 
    Typography, 
    Divider 
} from "@material-ui/core";
import Image from "material-ui-image"
import { Link as RouterLink } from "react-router-dom";

/**
 * This component displays a product card
 * Rendered by ProductsPane.js and ProductDescription.js
 */
class ProductCard extends Component{

    /**
     * Renders a product card
     * @param {
     *  {Integer} key - Uniquely identifies the card on a page,
     *  {Object} productData - Product details of a product, 
     *  {Object} styles - Object to apply css on various components
     * } props - Props supplied by the parent
     */
    render(){
        const productData = this.props.productData;
        const sku = productData.sku;
        const styles = this.props.styles;

        // Fades the images of out-of-stock products
        const opacity = productData.org_stock_available==="True" ? 1.0: 0.3;

        return (
            <Card style = {styles.Card}>
                <CardActionArea 
                    component={RouterLink} 
                    to={{
                        pathname: "/product-description", 
                        search: '?sku='+sku + '?categoryPageSearch=' + this.props.categoryPageSearch,
                    }}
                    target="_blank"
                >
                    {/** Image is not a component of material-ui. Is's coming from material-ui-image*/}
                    <Image
                        imageStyle = {styles.Image}
                        src = {productData.org_image_url}
                        style = {{opacity: opacity}}
                    />
                    <CardContent>
                        <Typography 
                            noWrap 
                            gutterBottom 
                            variant="subtitle1" 
                            style={styles.Typography}
                        >
                            {productData.brand}
                        </Typography>

                        <Typography 
                            noWrap 
                            variant="body2" 
                            color="textSecondary" 
                            component="p" 
                            style={styles.Typography}
                        >
                            {productData.org_product}
                        </Typography>

                        <Typography 
                            variant="h6" 
                            component="p"
                        >
                            ${productData.org_price}
                        </Typography>  
                    </CardContent>
                </CardActionArea>

                <Divider />

                <CardActions>
                    <Button 
                        size="small" 
                        color="primary"
                        component={RouterLink} 
                        to={{
                            pathname: "/product-description", 
                            search: '?sku='+sku + '?categoryPageSearch=' + this.props.categoryPageSearch,
                        }}
                        target="_blank"
                    >
                        View Details
                    </Button>
                </CardActions>
            </Card>
        );
    }
}

export default ProductCard;