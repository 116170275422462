/**
 * @author - Vaibhav Varshney
 * @author - Montek Singh
 * @author - Abhilash Pandurangan
 * @version - 1.0
 */
import React, { Component } from "react";
import {
  Grid,
  Backdrop,
  CircularProgress,
  withStyles,
  Drawer,
  CardActionArea,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import QueryHeader from "../QueryHeader/QueryHeader";
import NLPResultsSubHeader from "../NLPResultsSubHeader/NLPResultsSubHeader";
import ProductsPane from "../ProductsPane/ProductsPane";
import FiltersPane from "../FiltersPane/FiltersPane";
import NoResultsFound from "../Error/NoResultsFound";
import { API_URL } from "../../Constants/API_URL";
import { RESULTS_PER_PAGE } from "../../Constants/Constants"
import { DEFAULT_PRICE_RANGE } from "../../Constants/Constants"
import { ViewArrayRounded } from "@material-ui/icons";

/**
 * Hides the filters pane on "xs" screens
 * @param {Object} theme 
 */
const useStyles = (theme) => ({
  filtersPane: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
});

/**
 * This component displays the results page
 * Rendered by App.js
 */
class Results extends Component {

  /**
   * Defines the state
   * @param {*} props - Props supplied by the parent
   */
  constructor(props) {
    super(props);

    this.state = {
      isFilterDrawerOpen: false,
      error: null,
      isLoaded: false,
      rawQuery: this.props.location.search.split("?")[1].split("=")[1].split("%20").join(" "),
      categoryPageSearch: (this.props.location.search.split("?")[2].split("=")[1].split("%20").join(" ")==='true'),
      correctedQuery: "",
      facetFields: {},
      nlpResult: {},
      pageCount: 0,
      resultsCount: 0,
      currentPage: 1,
      solrQuery: {},
      solrResults: [],
      filters: {},
      sortOrder: 'best match'
    };
    /**
     * If the rawQuery is null
     * Then redirect to the homepage
     */
    if (this.state.rawQuery == null) {
      window.location.href = "/";
    }
  }

  /**
   * Loads the data for the first page
   * Lifecycle function of React which is invoked after all the components are laoded 
   */
  componentDidMount() {
    this.loadPageWithIndex(1, "raw-query");
  }

  /**
   * Function to Open/close the filter drawer on mobile devices
   */
  handleDrawerToggle = () => {
    this.setState({ isFilterDrawerOpen: !this.state.isFilterDrawerOpen });
  };

  /**
   * Redirects the user to home page  
   * in case of any error occurred 
   * while consuming Frenzy Search API
   */
  handleAPIError() {
    alert("Failed to load results!! Redirecting to HOME Page.");
    window.location.href = "/";
  }

  // 
  /**
   * Loads the data, returned by Frenzy Seatch API, to the state
   * @param {Number} index - Index value of the page to be loaded
   */
  loadPageWithIndex(index, mode) {

    //Take to the top of the page
    window.location.href = "#";

    this.setState({ isLoaded: false });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-Frenzy-Authorization", API_URL.APIKey);

    let requestBody = {
        mode: mode,
        page_index: index - 1,
        raw_query: this.state.rawQuery,
        query: this.state.solrQuery,
        filters: this.state.filters,
        sort: this.state.sortOrder
      }

    if (requestBody.sort==='normal'){
      delete requestBody.sort;
    }

    if(this.state.categoryPageSearch){
      delete requestBody.query;
    }

    if(mode==='raw-query'){
      delete requestBody.filters
    }
    
    let rawData = JSON.stringify(requestBody);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawData,
      redirect: "follow",
    };

    // Call the API
    fetch(API_URL.searchAPI, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if ("error" in result) {
            this.handleAPIError();
          }

          if("corrected_query" in result){
            this.setState({
              correctedQuery: result.corrected_query,
            });
          }

          if("nlp_result" in result){
            this.setState({
              nlpResult: result.nlp_result,
            });
          }
          this.setState({
            isLoaded: true,
            facetFields: result.facet_fields,
            pageCount: result.page_count,
            resultsCount: result.products_found,
            currentPage: index,
            solrQuery: result.query,
            solrResults: result.results,
            filters: result.filters,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: error,
          });
          this.handleAPIError();
        }
      );
  }
 
  /**
   * Function to apply the price filter
   * @param {Number} min - Min price value
   * @param {Number} max - Max price value
   */
  applyPriceFilter(min, max) {
    // Make a copy of the state
    let state = Object.assign({}, this.state);
    state.filters.price.min = min;
    state.filters.price.max = max;

    this.setState({
      state: state,
    });

    // Load the page with index "1"
    this.loadPageWithIndex(1, "filter-change");
  }

  /**
   * Function to load results for the new query on the results page
   * @param {String} userQuery - Query entered by user on results page
   */
  handleSearch(userQuery) {
    this.setState(
      {rawQuery:userQuery, correctedQuery:"", solrQuery:{}}, 
      function () {
        this.loadPageWithIndex(1, "raw-query");
      }
    );
  }

  /**
   * Funtion to update the filters
   * @param {*} filterName 
   * @param {*} subCategoryName 
   * @param {*} value 
   * @param {*} updateType 
   */
  filterUpdate(filterName, subCategoryName, value, updateType) {
    // Make a copy of the state
    let state = Object.assign({}, this.state);
    //console.log("filterUpdate state", state);
    //console.log(filterName, subCategoryName, value, updateType);

    if (updateType === "deleteChips") {
      let index = state.filters[filterName].indexOf(subCategoryName);
      state.filters[filterName].splice(index, 1);
      if (state.filters[filterName].length === 0) {
        delete state.filters[filterName];
      }
    } else if (updateType === "clearAllChips") {
      delete state.filters[filterName];
    } else if (updateType === "handleChange") {
      if (value === true) {
        //console.log("state.filters[filterName]", state.filters[filterName]);
        if (filterName in state.filters) {
          state.filters[filterName].push(subCategoryName);
        } else {
          state.filters[filterName] = [];
          state.filters[filterName].push(subCategoryName);
        }
      } else {
        let index = state.filters[filterName].indexOf(subCategoryName);
        state.filters[filterName].splice(index, 1);
        if (state.filters[filterName].length === 0) {
          delete state.filters[filterName];
        }
      }
    } else if (updateType === "radioButton") {
      //console.log("state.filters[filterName]", state.filters[filterName]);
      state.filters[filterName] = [subCategoryName];
    }
    this.setState({
      state: state,
    });
    //console.log("Filter Updates", this.state.filters);

    // Load the page with index "1"
    this.loadPageWithIndex(1, "filter-change");
  }

  /**
   * Function to remove filters
   * Onclick of "X" in the chips of the filters in products pane
   * @param {[filter_name, selected_value_for_filter]} value - Array of the selected filter data
   */
  deleteFilterChip(value){
    if(value[0] === "price"){
        this.applyPriceFilter(DEFAULT_PRICE_RANGE.min, DEFAULT_PRICE_RANGE.max);
    }
    else{
      const updateType = value[0] === "brand"? "deleteChips" : "handleChange";
      this.filterUpdate(value[0], value[1], false, updateType);
    }
  }
  /**
   * Function change the sort order
   * @param {string} sortOrder - the order in which the results have to be sorted
   */
  handleSortOrderChange(sortOrder){
    this.setState(
        {sortOrder: sortOrder},
        function () {
        this.loadPageWithIndex(1, "filter-change");
      });
  }
  /**
   * Renders the results page
   */
  render() {
    const classes = this.props.classes;

    /**
     * Filters Pane
     */
    const filtersPane = (
        <FiltersPane
          solrFacetResults={this.state.facetFields}
          selectedFilters={this.state.filters}
          defaultPriceRange={DEFAULT_PRICE_RANGE}
          filterUpdate={(filterName, subCategoryName, value, updateType) =>
            this.filterUpdate(filterName, subCategoryName, value, updateType)
          }
          applyPriceFilter={(min, max) => this.applyPriceFilter(min, max)}
          categoryPageSearch={this.state.categoryPageSearch}
        />
      );

    /**
     * Header
     */
    const header = (
      <Grid container direction="column" spacing={2}>

        <Grid item style={{minHeight: 70}}>
          <QueryHeader
            handleSearch={(userQuery) => this.handleSearch(userQuery)}
            rawQuery={this.state.rawQuery}
            openFiltersPane={this.handleDrawerToggle}
            categoryPageSearch={this.state.categoryPageSearch}
          />
        </Grid>

        <Grid item>
          <NLPResultsSubHeader
            correctedQuery={this.state.correctedQuery}
            nlpResult={this.state.nlpResult}
          />
        </Grid>

      </Grid>
    );

    /**
     * Body
     */
    const body = this.state.isLoaded && this.state.pageCount === 0 ?
      (<NoResultsFound />)
      :
      (
        <Grid container spacing={3} style={{ margin: 0, width: "100%" }}>

          <Grid item md={3} sm={5} className={classes.filtersPane}>
            
            {/**Displays the filter drwaer on "xs" screens */}
            <Drawer
              open={this.state.isFilterDrawerOpen}
              onClose={this.handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
            >
              <Grid item style={{ backgroundColor: "black" }}>
                <CardActionArea component={RouterLink} to="/">
                  <img
                    src="frenzylogo8.png"
                    alt="logo"
                    style={{ height: 40, padding: 10 }}
                  />
                </CardActionArea>
              </Grid>
              {filtersPane}
            </Drawer>

            {/**Displays the filter's pane on "sm" or large screens */}
            {filtersPane}
          </Grid>

          <Grid item md={9} xs={12} sm={7}>
            <ProductsPane
              solrResults={this.state.solrResults}
              pageCount={this.state.pageCount}
              resultsCount={this.state.resultsCount}
              currentPage={this.state.currentPage}
              resultsPerPage={RESULTS_PER_PAGE}
              filters={this.state.filters}
              defaultPriceRange={DEFAULT_PRICE_RANGE}
              loadPage={(index) => this.loadPageWithIndex(index, "page-change")}
              deleteFilterChip={(value) => this.deleteFilterChip(value)}
              categoryPageSearch={this.state.categoryPageSearch}
              sortOrder={this.state.sortOrder}
              handleSortOrderChange={(sortOrder) => this.handleSortOrderChange(sortOrder)}
            />
            <Backdrop
              style={{ zIndex: 1, color: "#fff" }}
              open={!this.state.isLoaded}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>

        </Grid>
      );

    return (
      <Grid container>
        { header }
        { body }  
      </Grid>
    );
  }
}

export default withStyles(useStyles)(Results);