/**
 * @author - Montek Singh
 * @version - 1.0
 */
import React, { Component } from "react";

import BrandsFilter from "./BrandsFilter";
import CheckboxFilter from "./CheckboxFilter";
import RadioButtonFilter from "./RadioButtonFilter";
import PriceFilter from "./PriceFilter";
import { Paper, Typography } from "@material-ui/core";
/**
 * This component renders the filters pane on results page
 * Rendered by Results.js
 */
class FiltersPane extends Component {
  constructor(props) {
    super(props);
    this.solrFacetResults = null;
    this.selectedFilters = null;
  }

  /**
   * Function to create button states
   * The button states are stored locally in 'this.localState.buttonState'
   *
   * @param {*} props
   */
  create_filter_pane(props) {
    let buttonStates = {};

    // all the classes which will be displayed
    let classesToShow = [];

    this.solrFacetResults = Object.assign({}, props.solrFacetResults);
    //console.log("solrFacetResults create_filter_pane", this.solrFacetResults);

    // list of initially selected filters
    this.selectedFilters = Object.assign({}, props.selectedFilters);
    //console.log("selected Filters create_filter_pane", this.selectedFilters);

    // creating local button states
    for (let oneCategory in this.solrFacetResults) {
      classesToShow.push(oneCategory);
      //console.log("One category", oneCategory);
      if (oneCategory === "category") {
        classesToShow.push("price");
      }
      let oneCategoryData = this.solrFacetResults[oneCategory];
      buttonStates[oneCategory] = {};
      for (let oneSubCategory in oneCategoryData) {
        // all state variables have a prefix of 'classsName_'
        let oneSubCategoryData =
          oneCategory + "_" + oneCategoryData[oneSubCategory][0];

        // handle preselected filters
        if (oneCategory in this.selectedFilters) {
          if (
            this.selectedFilters[oneCategory].includes(
              oneCategoryData[oneSubCategory][0]
            )
          ) {
            buttonStates[oneCategory][oneSubCategoryData] = true;
          } else {
            buttonStates[oneCategory][oneSubCategoryData] = false;
          }
        } else {
          buttonStates[oneCategory][oneSubCategoryData] = false;
        }
      }
    }

    // changing the Brand results in the solrFacetResults
    if ("brand" in this.solrFacetResults) {
      let brandSolrFacetResultsArray = this.solrFacetResults.brand;
      let brandSolrFacetResultsArrayOfJson = [];

      for (let brandIndex in brandSolrFacetResultsArray) {
        let oneBrandDataArray = brandSolrFacetResultsArray[brandIndex];
        brandSolrFacetResultsArrayOfJson.push({
          title: oneBrandDataArray[0],
          count: oneBrandDataArray[1],
        });
      }
      this.solrFacetResults.brand = brandSolrFacetResultsArrayOfJson;
      //console.log("brandSolrFacetResultsArrayOfJson", this.solrFacetResults);
    }
    //console.log("buttonStates", buttonStates);
    //console.log("classesToShow", classesToShow);

    // local state to manage buttonStates
    this.localState = {
      buttonState: buttonStates,
    };
    this.classesToShow = classesToShow;
  }

  /**
   * function to delete a single chip
   * @param {String} chipName
   * @param {String} filterName
   */
  deleteChips = (chipName, filterName) => {
    //console.log("parent", chipName, filterName);

    this.localState.buttonState[filterName][filterName + "_" + chipName] = !this
      .localState.buttonState[filterName][filterName + "_" + chipName];

    //this.setState({ state: state });
    this.props.filterUpdate(filterName, chipName, null, "deleteChips");
  };

  /**
   * function to clear all the Chips
   * @param {*} event
   * @param {String} filterName
   */
  clearAllChips = (event, filterName) => {
    //console.log("filterName", event.target.name, filterName);

    for (let one_filter_data in this.localState.buttonState[filterName]) {
      this.localState.buttonState[filterName][one_filter_data] = false;
    }

    //console.log("state.buttonState[filterName]", state.buttonState[filterName]);
    this.props.filterUpdate(filterName, null, "all", "clearAllChips");
  };

  /**
   * create a handler to accept events
   * @param {*} event
   */
  handleChange = (event) => {
    let filterName = event.target.name.split("_")[0];
    //console.log("filter name", filterName);
    this.localState.buttonState[filterName][event.target.name] = !this
      .localState.buttonState[filterName][event.target.name];

    //console.log("end of handleChange", this.state);

    this.props.filterUpdate(
      filterName,
      event.target.name.split("_")[1],
      this.localState.buttonState[filterName][event.target.name],
      "handleChange"
    );
  };

  /**
   * handler for radio button will set only one sub category
   * @param {*} event
   */
  handleChangeRadioButton = (event) => {
    let filterName = event.target.name.split("_")[1];

    let selectedItem = event.target.value;

    for (let oneSubCategory in this.localState.buttonState[filterName]) {
      if (oneSubCategory === selectedItem) {
        this.localState.buttonState[filterName][oneSubCategory] = true;
      } else {
        this.localState.buttonState[filterName][oneSubCategory] = false;
      }
    }

    this.props.filterUpdate(
      filterName,
      selectedItem.split("_")[1],
      null,
      "radioButton"
    );
  };

  /**
   * Given a input Filter name the code will return the Component
   * @param {String} filterName
   */
  getUIElements(filterName) {
    if (filterName === "brand") {
      return (
        <BrandsFilter
          solrFacetResults={this.solrFacetResults[filterName]}
          handleChange={this.handleChange}
          buttonStates={this.localState.buttonState}
          filterName={filterName}
          deleteChips={this.deleteChips}
          clearAllChips={this.clearAllChips}
          key={"filter_" + filterName}
        />
      );
    } else if (filterName === "price") {
      return (
        <PriceFilter
          defaultPriceRange={this.props.defaultPriceRange}
          selectedFilters={this.props.selectedFilters}
          onApply={(min, max) => this.props.applyPriceFilter(min, max)}
          key={"filter_" + filterName}
        />
      );
    } else if (filterName === "category" && !this.props.categoryPageSearch) {
      return (
        <RadioButtonFilter
          solrFacetResults={this.solrFacetResults[filterName]}
          handleChange={this.handleChange}
          buttonStates={this.localState.buttonState}
          filterName={filterName}
          handleChangeRadioButton={this.handleChangeRadioButton}
          key={"filter_" + filterName}
        />
      );
    } else {
      return (
        <CheckboxFilter
          solrFacetResults={this.solrFacetResults[filterName]}
          handleChange={this.handleChange}
          buttonStates={this.localState.buttonState}
          filterName={filterName}
          key={"filter_" + filterName}
        />
      );
    }
  }

  /**
   * Render the FiltersPane component only when results from API change when compared to the data in props.
   * @param {*} nextProps
   * @param {*} nextState
   */
  shouldComponentUpdate(nextProps, nextState) {
    //console.log("CURRENT PROPS", this.props);
    //console.log("NEXT PROPS", nextProps);
    if (
      this.props.solrFacetResults === nextProps.solrFacetResults &&
      this.props.selectedFilters === nextProps.selectedFilters
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    //console.log("API results recieved", this.props.resultsLoadedBool);
    //console.log("API facets", this.props.solrFacetResults);

    //console.log("IN this.runCreateFiltersPane", this.runCreateFiltersPane)

    // create the filter pane along with the button states
    this.create_filter_pane(this.props);

    //console.log("Reder buttonState", this.state.buttonState);
    // JSON array which stores how each class has to be represented
    return (
      <Paper>
        <Typography variant="h5" style={{ padding: 15 }}>
          Filters
        </Typography>
        {this.classesToShow.map((oneCategory) => {
          return this.getUIElements(oneCategory);
        })}
      </Paper>
    );
  }
}

export default FiltersPane;
