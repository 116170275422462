/**
 * @author - Montek Singh
 * @version - 1.0
 */
import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Chip,
  GridList,
  Checkbox,
  TextField,
} from "@material-ui/core";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = (theme) => ({
  topography: {
    display: "inline",
    fontSize: "0.65rem",
    color: "grey",
    paddingLeft: "10px",
  },
  summary: {
    width: "100%",
  },
  gridList: {
    maxHeight: 200,
    width: "100%",
  },
});

/**
 * This component renders the brand filter of filters pane on results page
 * Rendered by FiltersPane.js
 */
class BrandsFilter extends Component {
  /**
   * props- supplied by parent
   * styleClasses- to create the style with withStyles component
   */
  styleClasses = this.props.classes;

  /**
   *     
    To create data which will be used to populate the Dropdown for auto fill
    @returns {Object} selectedValues
      list of dictionary
      of the format-> [{title: 'title_1', count: 10}]
      count is hard coded for now as its not required
      
   */
  getSelectedValues() {
    let selectedValues = [];

    let buttonStatesForFilter = this.props.buttonStates[this.props.filterName];

    for (let one_button_state in buttonStatesForFilter) {
      if (buttonStatesForFilter[one_button_state] === true) {
        selectedValues.push({
          title: one_button_state.split("_")[1],
          count: 10,
        });
      }
    }
    return selectedValues;
  }

  render() {
    // get data for Dropdown List
    //let getSelectedValuesData = this.getSelectedValues();

    return (
      <ExpansionPanel
        className={this.styleClasses.summary}
        key={"ExpansionPanel_" + this.props.filterName}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ textTransform: "capitalize" }}>
            {this.props.filterName}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <GridList
            cellHeight="auto"
            className={this.styleClasses.gridList}
            cols={1}
          >
            <Autocomplete
              value={this.getSelectedValues()}
              multiple
              id="checkboxes-tags-demo"
              options={this.props.solrFacetResults}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderTags={(getSelectedValuesData, getTagProps) =>
                getSelectedValuesData.map((option, index) => {
                  // to chips are modified to handle delete
                  return (
                    <Chip
                      variant="outlined"
                      key={option.title}
                      label={option.title}
                      style={{ textTransform: "capitalize" }}
                      {...getTagProps({ index })}
                      name={
                        "chip_" + this.props.filterName + "_" + option.title
                      }
                      onDelete={() =>
                        this.props.deleteChips(
                          option.title,
                          this.props.filterName
                        )
                      }
                    />
                  );
                })
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    color="primary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={
                      this.props.buttonStates[this.props.filterName][
                        this.props.filterName + "_" + option.title
                      ]
                    }
                    name={this.props.filterName + "_" + option.title}
                    onChange={this.props.handleChange}
                  />
                  <Typography style={{ textTransform: "capitalize" }}>
                    {option.title}
                  </Typography>
                  <Typography className={this.styleClasses.topography}>
                    {"(" + option.count + ")"}
                  </Typography>
                </React.Fragment>
              )}
              style={{ width: "100%", padding: "15px" }}
              renderInput={(params) => {
                //console.log("params", params);
                // Text Field where the user enters the data
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Brands"
                    placeholder="Pls enter brand"
                  />
                );
              }}
              closeIcon={
                // Close Icon is modified to remove Chips
                <CloseIcon
                  fontSize="small"
                  onClick={(event) =>
                    this.props.clearAllChips(event, this.props.filterName)
                  }
                />
              }
            />
          </GridList>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(useStyles)(BrandsFilter);
