import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


export default function SortResults(props) {
  const handleChange = (event) => {
    props.handleSortOrderChange(event.target.value);
  };

  return (
    <div>

      <FormControl variant="outlined">
        <InputLabel id="sortOrderInput"
        style={{fontSize:15}}>Sort By</InputLabel>
        <Select
          labelId="sortOrderSelectorLabel"
          id="sortOrderSelectorId"
          value={props.sortOrder}
          onChange={handleChange}
          label="Sort By"
          style={{fontSize:15, height: 40}}
        >
          <MenuItem value={"best match"}>Best Match</MenuItem>
          <MenuItem value={"newest"}>Newest First</MenuItem>
          <MenuItem value={"price asc"}>Price: Low - High</MenuItem>
          <MenuItem value={"price desc"}>Price: High - Low</MenuItem>
          <MenuItem value={"normal"}>Normal</MenuItem>
        </Select>
      </FormControl>

    </div>
  );
}
