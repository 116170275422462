/**
 * @author - Abhilash Pandurangan
 * @version - 1.0
 */
import React, {Component} from "react";
import {
    Grid, 
    Typography, 
    InputBase, 
    IconButton, 
    Paper, 
    AppBar, 
    Toolbar,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

/**
 * This component is rendered as index page of the serach portal.
 * Rendered by App.js
 */
class Home extends Component{
    /**
     * Declares the state variable
     * @param {*} props - Props supplied by the parent
     */
    constructor(props){
        super(props);
        this.state = {
            value: "",
            categoryPageCheckBox: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
    }

    /**
     * Update the checkbox
     */
    handleCheckBoxClick(){
        console.log('hello')
        let presentState = this.state.categoryPageCheckBox;
        this.setState({categoryPageCheckBox:!presentState});
        console.log('state', this.state)
    }


    /**
     * Updates the state as the user types the query
     * @param {*} event - Event object of the InputBase(Search Bar)
     */
    handleChange(event){
        this.setState({value:event.target.value});   
       }
    
    /**
     * Push the path of the results page and the query onto the location props
     */
    handleSubmit(){
        const { history } = this.props;
        history.push({
            pathname : '/results',
            search : '?query='+this.state.value+'?categoryPageSearch='+this.state.categoryPageCheckBox,
        });
    }
       
    /**
     * Renders the Home page
     */
    render(){
        
        return(
            <Grid container >
                <Grid container>
                    <AppBar style={{"backgroundColor": "black"}}>
                        <Toolbar>
                            <Grid item>          
                                <img src = "frenzylogo8.png" alt= "logo" style={{height: 40}}/>
                            </Grid>
                        </Toolbar> 
                    </AppBar>
                </Grid>
                <Grid container style={{marginTop: 140}}>
                    <Grid container justify="center" style={{ margin: 0, width: "100%" }} direction="row">
                        <Grid item md={12}>
                            <Grid container justify="center" alignItems="center" spacing= {1} direction="column">
                                <Grid item>
                                    <Typography variant="h2">
                                        A.I. Powered Search
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">
                                        Increase site search relevance and conversion 200%+
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                
                        <Grid item  sm={7} xs={12} style={{marginTop: 80}}>
                            <Paper component = 'form'  variant="outlined" > 
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Grid item md={11} xs={10}>
                                    <InputBase
                                    placeholder="Search for Clothing, Bags and more.."
                                    value={this.state.value}
                                    onChange= {this.handleChange}
                                    style={{width: "100%", padding: 10}}
                                    />
                                    </Grid>
                                    <Grid item md={1} xs={2}> 
                                        <Grid container justify="flex-end">
                                            <IconButton  type = 'submit' onClick = {this.handleSubmit} style={{align: "right"}}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems='center' direction="row" justify='center' style={{ margin: 20}}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                  <Checkbox
                                        color="primary"
                                        checked={this.state.categoryPageCheckBox}
                                        onChange={this.handleCheckBoxClick}
                                        name='categoryPageSearch'
                                        key='categoryPageSearch'
                                  />
                            }
                        label="Category Page Search"
                        style={{ textTransform: "capitalize" }}
                      />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default Home;