/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, {Component} from "react";
import {
    TableContainer, 
    Table, 
    TableHead, 
    TableBody, 
    TableRow, 
    TableCell, 
    Paper, 
    Typography
} from "@material-ui/core";

/**
 * This component displays the product details table
 * Rendered by ProductDescription.js
 */
class ProductDescriptionTable extends Component {
      
    /**
     * Renders the product details table
     * @param {
     *  {Object} productData - Object of the product details, 
     *  {Object} styles - Object of the styles used
     * } props - Props supplied by the parent
     */
    render(){
        const productData = this.props.productData;
        const styles = this.props.styles;

        return (
        <TableContainer component={Paper} style={styles.Table}>
            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography style={{fontWeight:"bold"}}>Attribute</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography style={{fontWeight:"bold"}}>Value</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {Object.keys(productData).map(function (key, index) {
                        if (!
                                (
                                    key==="org_image_url" || 
                                    key==="org_product" || 
                                    key==="org_description" || 
                                    key==="org_price" || 
                                    key==="size" ||
                                    key==="org_url"
                                )
                            ){
                            return(
                                <TableRow key={index}>
                                    <TableCell>
                                        <Typography variant="body2" style={styles.Typography}> { key } </Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography variant="body2" style={styles.Typography}> { productData[key] } </Typography>
                                        {/* A warning because of the filed "id" is thrown from here*/}
                                    </TableCell>
                                </TableRow>
                            );
                        }

                        else{
                            return null;
                        }
                    })}
                </TableBody>

            </Table>
        </TableContainer>
        );
    }
}

export default ProductDescriptionTable;