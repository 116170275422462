/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, {Component} from "react";
import {
    Grid, 
    Button, 
    Typography, 
    Paper
} 
from "@material-ui/core";
import QueryDetails from "./QueryDetails";

/**
 * This component displays the NLP results
 * Rendered by Results.js
 */
class NLPResultsSubHeader extends Component{
    /**
     * Constructor
     * @param {{String} correctedQuery, {Object} nlpResult} props - Props supplied by the parent
     */
    constructor(props){
        super(props);
        this.state={
            isQueryDetailsOpen: false,
        };

        this.style={
            Button:{
                backgroundColor: "#757de8", 
                color: "white", 
                textTransform: "none",
            }
        };

        this.openQueryDetails = this.openQueryDetails.bind(this);
    }

    /**
     * Closes the query details Dialog Box
     * Called by QueryDetails.js
     */
    closeQueryDetails(){
        let isQueryDetailsOpen = this.state.isQueryDetailsOpen;
        isQueryDetailsOpen = false;
        this.setState({isQueryDetailsOpen: isQueryDetailsOpen});
    }

    /**
     * Opens the query details Dialog Box
     */
    openQueryDetails(){
        let isQueryDetailsOpen = this.state.isQueryDetailsOpen;
        isQueryDetailsOpen = true;
        this.setState({isQueryDetailsOpen: isQueryDetailsOpen});
    }

    /**
     * Renders the NLP Results
     */
    render(){

        return(
            <Grid container justify="center" spacing={2}>

                <Grid item>
                    <Paper>
                        <Button disabled variant="contained" color="primary" style={this.style.Button}>
                            <Typography>
                                Showing results for: {this.props.correctedQuery}
                            </Typography>
                        </Button>
                    </Paper>
                </Grid>

                <Grid item>
                    <Button onClick={this.openQueryDetails} variant="contained" color="primary" style={this.style.Button}>
                        <Typography>
                            Query Details
                        </Typography>
                    </Button>
                    <QueryDetails 
                        nlpResult={this.props.nlpResult}
                        isQueryDetailsOpen={this.state.isQueryDetailsOpen}
                        closeQueryDetails={() => this.closeQueryDetails()}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default NLPResultsSubHeader;