/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, { Component } from "react";
import {
    Grid, 
    Typography, 
    Divider, 
    ExpansionPanel, 
    ExpansionPanelSummary, 
    ExpansionPanelDetails, 
    CircularProgress
} from "@material-ui/core";
import Image from "material-ui-image";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductDescriptionTable from "./ProductDescriptionTable";
import ProductCard from "./ProductCard";
import ProductDescQueryHeader from "../QueryHeader/ProductDescQueryHeader"
import { API_URL } from "../../Constants/API_URL"
import { NUM_SIMILAR_PRODUCTS } from "../../Constants/Constants"

/**
 * This component displays the product description page
 * Rendered by App.js
 */
class ProductDescription extends Component {
    /**
     * Defines the state
     * Loads the product description section and the similar products section
     * @param {*} props - Props supplied by the parent
     */
    constructor(props){
        super(props);
        this.state = {
            productDataLoadingError: null,
            similarProductsDataLoadingError: null,
            sameDesignerProductsDataLoadingError: null,
            isProductDataLoaded: false,
            isSimilarProductsDataLoaded: false,
            isSameDesignerProductsDataLoaded: false,
            sku: this.props.location.search.split("?")[1].split("=")[1].split("%20").join(" "),
            productData: {},
            similarProducts: [],
            sameDesignerProducts: [],
            categoryPageSearch: (this.props.location.search.split("?")[2].split("=")[1].split("%20").join(" ")==='true'),
        };

    this.getProductData();
    this.getSimilarProducts();
    this.getSameDesignerProducts();
    }

    /**
     * Handles the errors occurred during rest API calls
     * @param {String} error - Error message to be displayed in the alert box.
     */
    handleAPIError(error) {
        alert(error + " Redirecting to HOME Page.");
        window.location.href = "/";
      }

    /**
     * Loads the product data from product data API
     */
    getProductData(){
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-Frenzy-Authorization", API_URL.APIKey);
        let rawData = {};

        rawData = JSON.stringify({
            sku: this.state.sku
        });

        let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: rawData,
        redirect: "follow",
        };

        fetch(API_URL.productDataAPI, requestOptions)
        .then((res) => res.json())
        .then(
            (result) => {
                if ("error" in result) {
                    this.handleAPIError("Unable to find the product!!");
                  }

                this.setState({
                    isProductDataLoaded: true,
                    productData: result.product_data,
                });

            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isProductDataLoaded: true,
                    productDataLoadingError: error,
                });

                this.handleAPIError("Some internal error occurred!!");
            }
        );
    }

    /**
     * Loads the similar products from recommendation API
     */
    getSimilarProducts(){
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-Frenzy-Authorization", API_URL.APIKey);
        let rawData = {};

        rawData = JSON.stringify({
            sku: this.state.sku,
            num_matching: NUM_SIMILAR_PRODUCTS,
            full_description: true
        });

        let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: rawData,
        redirect: "follow",
        };

        fetch(API_URL.recommendationAPI, requestOptions)
        .then((res) => res.json())
        .then(
            (result) => {
                if("matching_products" in  result){
                    this.setState({
                        similarProducts: result.matching_products,
                    });
                } 
                
                this.setState({
                    isSimilarProductsDataLoaded: true,
                });

                //console.log("State after API CALL", this.state);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isSimilarProductsDataLoaded: true,
                    similarProductsDataLoadingError: error,
                });
            }
        );
    }
    /**
     * Loads the same designer products from API
     */
    getSameDesignerProducts(){
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-Frenzy-Authorization", API_URL.APIKey);
        let rawData = {};

        rawData = JSON.stringify({
            sku: this.state.sku,
            num_matching: NUM_SIMILAR_PRODUCTS,
            full_description: true
        });

        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: rawData,
            redirect: "follow",
            };
    
        fetch(API_URL.designerRecommendationAPI, requestOptions)
        .then((res) => res.json())
        .then(
            (result) => {
                if("matching_products" in  result){
                    this.setState({
                        sameDesignerProducts: result.matching_products,
                    });
                } 
                
                this.setState({
                    isSameDesignerProductsDataLoaded: true,
                });

                // console.log("State after API CALL", this.state);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isSameDesignerProductsDataLoaded: true,
                    sameDesignerProductsDataLoadingError: error,
                });
            }
        );
    }

    /**
     * Renders the ProductCard for each similar product
     * @param {Object} productData - Object of the product details
     * @param {Integer} index - Index of the card
     */
    renderProductCard(productData, index){
        const styles = {
            Card: {
                width: 209,
                height: 370,
            },

            Image:{
                width: 200,
                height: 200,
            },

            Typography:{
                textTransform: "capitalize",
            },
        };

        return(
            <Grid item key={index}>
                <ProductCard 
                    key={index} 
                    productData={productData} 
                    styles={styles}
                    categoryPageSearch={this.state.categoryPageSearch}
                />
            </Grid>
        );
    }
  
    /**
     * Renders the product description page
     */
    render(){

        /**
         * Sets image URL to "" until the data is being loaded from the API
         * Else an exception would be raised because "org_image_url" can't be found in "state.productData"
         */
        const imgURL = this.state.isProductDataLoaded ? this.state.productData.org_image_url : "";

        // Creating a product card for every similar product returned by recommendation API
        let productCards = this.state.similarProducts.map((productData, index) => {
            return this.renderProductCard(productData, index);
        });

        // Creating a product card for every same designer product returned by same designer API
        let sameDesignerProductCards = this.state.sameDesignerProducts.map((productData, index) => {
            return this.renderProductCard(productData, index);
        });

        const styles = {
            Card: {
                width: 209,
                height: 370,
            },

            Image:{
                width: 200,
                height: 200,
            },
            Table:{
                marginTop: 0,
                width: "100%",
            },
            Typography:{
                textTransform: "capitalize",
            },
        };

        /**
         * Renders the product details section of the product descripion page
         * DATA is LOADING - Renders the Circular progress
         * DATA LOADED - Renders the product description section of the page
         */
        const productDetails = this.state.isProductDataLoaded?
        (
            <Grid container spacing={3} style={{ margin: 0, width: "100%" }} >
                <Grid item md sm xs={12}>
                    <Image src={imgURL}/>
                </Grid>
                <Grid item md={7} sm={7} xs={12}>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <Typography 
                                style={styles.Typography}
                                variant="h4"
                            >
                                {this.state.productData.brand}
                            </Typography>
                            <Typography 
                                style={styles.Typography}
                                variant="h6"
                                color="textSecondary"
                            >
                                {this.state.productData.org_product}
                            </Typography>
                            <Divider 
                                style={{marginTop: 5, marginBottom: 5}}
                            />
                            <Typography 
                                style={styles.Typography}
                                variant="h4"
                            >
                                ${this.state.productData.org_price}
                            </Typography>
                            <Typography 
                                style={styles.Typography}
                                variant="h6"
                                color="textSecondary"
                            >
                                SIZE - {this.state.productData.size}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                {this.state.productData.org_description}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                {this.state.productData.org_url}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>PRODUCT DETAILS</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <ProductDescriptionTable 
                                        productData={this.state.productData} 
                                        styles={styles}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
        :
        (
            <Grid container justify="center">
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );

        /**
         * Renders the similar products section of the product description page
         * DATA is LOADING - Renders the Circular progress
         * DATA LOADED - NO SIMILAR PRODUCTS FOUND  - Nothing is rendered
         *             - SIMILAR PRODUCTS FOUND     - Renders the ProductCards for the similar products
         */
        const similarProducts = this.state.isSimilarProductsDataLoaded ?
        (
            this.state.similarProducts.length !== 0 ?
            (
                <Grid container spacing={3} direction="column" style={{ margin: 0, width: "100%" }}>
                    <Grid item>
                        <Typography style={{marginLeft: 13}} variant="h6">
                            SIMILAR PRODUCTS
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container justify="space-evenly" spacing={3}>
                            {productCards}
                        </Grid>
                    </Grid>
                </Grid>
            )
            :
            (
                // No matching products were returned by the API
                null
            )
        ):
        (
            <Grid container justify="center">
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );


        /**
         * Renders the same designer products section of the product description page
         * DATA is LOADING - Renders the Circular progress
         * DATA LOADED - NO SAME DESIGNER PRODUCTS FOUND  - Nothing is rendered
         *             - SAME DESIGNER PRODUCTS FOUND     - Renders the ProductCards for the same designer products
         */
         const sameDesignerProducts = this.state.isSameDesignerProductsDataLoaded ?
         (
             this.state.sameDesignerProducts.length !== 0 ?
             (
                 <Grid container spacing={3} direction="column" style={{ margin: 0, width: "100%" }}>
                     <Grid item>
                         <Typography style={{marginLeft: 13}} variant="h6">
                             MORE FROM DESIGNER
                         </Typography>
                     </Grid>
                     <Grid item>
                         <Grid container justify="space-evenly" spacing={3}>
                             {sameDesignerProductCards}
                         </Grid>
                     </Grid>
                 </Grid>
             )
             :
             (
                 // No matching products were returned by the API
                 null
             )
         ):
         (
             <Grid container justify="center">
                 <Grid item>
                     <CircularProgress />
                 </Grid>
             </Grid>
         );
        
    return (
        <Grid container direction="column">

            <Grid item style={{minHeight: 70}}>
                <ProductDescQueryHeader  categoryPageSearch={this.state.categoryPageSearch}/>
            </Grid>

            <Grid item>
                <Grid container direction="column" spacing={3} style={{ margin: 0, width: "100%" }}>
                    { productDetails }

                    { similarProducts }

                    {sameDesignerProducts}
                </Grid>
            </Grid>
        </Grid>
    );
  }
}

export default ProductDescription;