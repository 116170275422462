/**
 * @author - Montek Singh
 * @version - 1.0
 */
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  GridList,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  topography: {
    display: "inline",
    fontSize: "0.65rem",
    color: "grey",
  },
  formControlLabel: {
    marginRight: "5px",
  },
  summary: {
    width: "100%",
  },
  gridList: {
    maxHeight: 200,
    width: "100%",
  },
});

/**
 * This component renders the Radio Button filters of filters pane on results page
 * Rendered by FiltersPane.js
 */
class RadioButtonFilter extends Component {
  styleClasses = this.props.classes;

  /**
   * Find any preselected Value uses the data from the API call
   */
  getPreselectedRadio() {
    let allSubCategories = this.props.buttonStates[this.props.filterName];
    for (let onesubCategoryIndex in allSubCategories) {
      if (allSubCategories[onesubCategoryIndex]) {
        return this.props.filterName + "_" + onesubCategoryIndex.split("_")[1];
      }
    }
    return null;
  }

  render() {
    //console.log("Button states", this.props.buttonStates);
    //console.log("solr facet results", this.props.solrFacetResults);
    return (
      <ExpansionPanel
        className={this.styleClasses.summary}
        key={"ExpansionPanel_" + this.props.filterName}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ textTransform: "capitalize" }}>
            {this.props.filterName}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <GridList
            cellHeight="auto"
            className={this.styleClasses.gridList}
            cols={1}
          >
            <FormControl
              component="fieldset"
              className={this.styleClasses.formControl}
            >
              <RadioGroup
                name={"radioGroup_" + this.props.filterName}
                value={this.getPreselectedRadio()}
                onChange={this.props.handleChangeRadioButton}
              >
                {this.props.solrFacetResults.map((oneSubCategory) => (
                  <Grid
                    container
                    alignItems="center"
                    key={
                      "div_" + this.props.filterName + "_" + oneSubCategory[0]
                    }
                  >
                    <Grid item>
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label={oneSubCategory[0]}
                        key={"brandFormControlLabel" + oneSubCategory[0]}
                        className={this.styleClasses.formControlLabel}
                        value={this.props.filterName + "_" + oneSubCategory[0]}
                        style={{ textTransform: "capitalize" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        className={this.styleClasses.topography}
                        noWrap
                      >
                        {"(" + oneSubCategory[1] + ")"}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </RadioGroup>
            </FormControl>
          </GridList>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(useStyles)(RadioButtonFilter);
