/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
import React, { Component } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/**
 * This component renders the price range filter of filters pane on results page
 * Rendered by FiltersPane.js
 */
class PriceFilter extends Component {

  /**
   * Defines the state
   * @param {
   *  {Object} defaultPriceRange - The default min/max values applied when user skips them, 
   *  {Object} selectedFilters - The state of the filter's pane, 
   *  {Function} onApply - Apllies the price filter on click of apply
   * } props - Props supplied by the parent
   */
  constructor(props) {
    super(props);

    this.state = {
      min: "",
      max: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.onApply = this.onApply.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  /**
   * Function to constanlty update the local state of the PriceFilter
   * i.e. min and max
   * @param {*} event 
   */
  handleChange(event) {
    if (event.target.name === "min") {
      this.setState({ min: event.target.value });
    } else if (event.target.name === "max") {
      this.setState({ max: event.target.value });
    }
  }

  /**
   * Function to pass on the mix and max values to FiltersPane via props
   * When Apply button is clicked
   */
  onApply() {
    let min = this.props.defaultPriceRange["min"];
    let max = this.props.defaultPriceRange["max"];
    if (this.state.min !== "") {
      min = this.state.min;
    }
    if (this.state.max !== "") {
      max = this.state.max;
    }
    //console.log("Apply Button Clicked", min,max);

    this.props.onApply(min, max);
  }

  /**
   * Function to reset the state variable when Clear button is clicked
   * Calls the onApply function of filters pane via props with default min and max values 
   */
  onClear() {
    this.setState({
      min: "",
      max: "",
    });

    this.props.onApply(this.props.defaultPriceRange["min"], this.props.defaultPriceRange["max"]);
  }

  /**
   * Clears the min and max values when the price range chip is deleted on the products pane
   * 
   * Lifecycle function of React and is invoked when the component receives new props
   * If the value of the price filter props changes and both the min and max values are default
   * Then clears the min and max values 
   * @param {Object} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.selectedFilters.price !== nextProps.selectedFilters.price){
      if((this.props.selectedFilters.price.max === this.props.defaultPriceRange.max && this.props.selectedFilters.price.min === this.props.defaultPriceRange.min) || (nextProps.selectedFilters.price.min === this.props.defaultPriceRange.min && nextProps.selectedFilters.price.max === this.props.defaultPriceRange.max)){
        this.setState({
          min: "",
          max: "",
        });
      }
    }
  }

  /**
   * Renders the price filter of the filter's pane
   */
  render() {
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Price Range</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <form noValidate autoComplete="off" onSubmit={this.onApply} style={{width: "100%"}}>
            <Grid
              container
              direction="column"
              justify="space-evenly"
              spacing={2}
            >

              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  direction="column"
                  justify="space-evenly"
                  spacing={1}
                >

                  <Grid item>
                    <TextField
                      label="$ Min"
                      name="min"
                      value={this.state.min}
                      onChange={this.handleChange}
                      variant="outlined"
                      type="number"
                      size="small"
                    />
                  </Grid>

                  <Grid item>
                    <Typography
                      align="center"
                      style={{ verticalAlign: "middle" }}
                    >
                      {" "}
                      To
                    </Typography>
                  </Grid>

                  <Grid item>
                    <TextField
                      label="$ Max"
                      name="max"
                      value={this.state.max}
                      onChange={this.handleChange}
                      variant="outlined"
                      type="number"
                      size="small"
                    />
                  </Grid>

                </Grid>
              </Grid>

              <Grid container justify="flex-end">
                <Button onClick={this.onClear} color="primary">
                  Clear
                </Button>
                <Button onClick={this.onApply} color="primary">
                  Apply
                </Button>
              </Grid>
            </Grid>
          </form>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default PriceFilter;
