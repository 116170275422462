/**
 * @author - Montek Singh
 * @version - 1.0
 */
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  GridList,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  topography: {
    display: "inline",
    fontSize: "0.65rem",
    color: "grey",
  },
  formControlLabel: {
    marginRight: "5px",
  },
  summary: {
    width: "100%",
  },
  gridList: {
    maxHeight: 200,
    width: "100%",
  },
});

/**
 * This component renders the Checkbox filters of filters pane on results page
 * Rendered by FiltersPane.js
 */
class CheckboxFilter extends Component {
  /**
   * props - Props supplied by the parent
   * styleClasses- useStyles data sent as props
   */

  styleClasses = this.props.classes;

  render() {
    //console.log("Button states", this.props.buttonStates);
    //console.log("solr facet results", this.props.solrFacetResults);
    return (
      <ExpansionPanel
        className={this.styleClasses.summary}
        key={"ExpansionPanel_" + this.props.filterName}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ textTransform: "capitalize" }}>
            {this.props.filterName}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <GridList
            cellHeight="auto"
            className={this.styleClasses.gridList}
            cols={1}
          >
            <FormControl
              component="fieldset"
              className={this.styleClasses.formControl}
            >
              <FormGroup>
                {this.props.solrFacetResults.map((oneSubCategory) => (
                  <Grid
                    container
                    alignItems="center"
                    key={
                      "div_" + this.props.filterName + "_" + oneSubCategory[0]
                    }
                  >
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              this.props.buttonStates[this.props.filterName][
                                this.props.filterName + "_" + oneSubCategory[0]
                              ]
                            }
                            onChange={this.props.handleChange}
                            name={
                              this.props.filterName + "_" + oneSubCategory[0]
                            }
                            key={
                              this.props.filterName + "_" + oneSubCategory[0]
                            }
                          />
                        }
                        label={oneSubCategory[0]}
                        key={"brandFormControlLabel" + oneSubCategory[0]}
                        className={this.styleClasses.formControlLabel}
                        style={{ textTransform: "capitalize" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        className={this.styleClasses.topography}
                        noWrap
                      >
                        {"(" + oneSubCategory[1] + ")"}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </FormGroup>
            </FormControl>
          </GridList>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(useStyles)(CheckboxFilter);
