/**
 * @author - Vaibhav Varshney
 * @version - 1.0
 */
const APIServerURL = "http://lgs.search.frenzy.ai/"

export const API_URL = {
    searchAPI: APIServerURL,
    productDataAPI: APIServerURL + "product-data",
    recommendationAPI: APIServerURL + "recommendation",
    designerRecommendationAPI: APIServerURL + "designer-recommendation",
    APIKey: "7b4fd25c-2522-4bfd-9345-9ae2b23a3c0d"
};