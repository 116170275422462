/**
 * @author - Vaibhav Varshney
 * @author - Montek Singh
 * @author - Abhilash Pandurangan
 * @version - 1.0
 */
import React, { Component } from "react";
import Results from "./Results/Results";
import Home from "./Home/Home";
import ProductDescription from "./ProductsPane/ProductDescription"
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";

/**
 * Renders the components based on the path
 * "/" - Home.js
 * "/results" - Results.js
 * "/product-description" - ProductDescription.js
 */
class App extends Component{
    render(){
        return (
        <Router>
            <Switch>
                <Route exact path = "/" component = {Home}/>
                <Route path = "/results" component = {Results} />
                <Route path = "/product-description" component = {ProductDescription}/>
            </Switch>
        </Router>
        )
    };
}

export default App;
